import React, { Suspense, lazy, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { resetCommonReducerErrorsAction } from "./redux/actions/main";
import { setPageNumberMealAction } from "./redux/actions/mealsAction";
import { setPageNumberDailyTaskAction } from "./redux/actions/dailyTasksAction";
import Layout from "./layouts/Layout";
import Login from "./views/Auth/Login";
import ForgotPassword from "./views/Auth/ForgotPassword";
import Dashboard from "./views/Dashboard.js";
import NewGuide from "./views/newGuideVideo.js";
import Workouts2 from "./views/Workouts.js";
import NewWorkouts from "./views/NewWorkouts.js";
import MealPlans from "./views/MealPlans.js";
import DailyTask from "./views/DailyTask.js";
import CalorieCalculations2 from "./views/CalorieCalculations.js";
import PushNotifications from "./views/PushNotifications.js";
import AppContent from "./views/AppContent.js";
import SubscriptionPlans from "./views/SubscriptionPlans.js";
import NewDailyTask from "./views/NewDailyTask.js";
import NewCalorieItem from "./views/newCalorieitem.js";
import NewPushNotifications from "./views/new-push-notifictaion.js";
import UpdateBanner from "./views/update-banner.js";
import NewSubscriptionPlan from "./views/new-subscription-plan.js";
import ChatSupport from "./views/ChatSupport/ChatSupport.js";
import Icons from "./views/Icons.js";

import { connect } from "react-redux";
import CustomerSubscriptionsView from "./views/Customers/Subscriptions/CustomerSubscriptionsView";
import PersonalTrainer from "./views/PersonalTrainer/PersonalTrainer.jsx";
import PersonalTrainerCreate from "./views/PersonalTrainer/PersonalTrainerCreate.jsx";
import PersonalTrainerEdit from "./views/PersonalTrainer/PersonalTrainerEdit.jsx";
import PackageTrainerPrice from "./views/PackagePrice/PackagePrice.jsx";
import PackageTrainerPriceEdit from "./views/PackagePrice/PackagePriceEdit.jsx";
import PackageTrainerPriceCreate from "./views/PackagePrice/PackagePriceCreate.jsx";
import TrainingExpertise from "./views/TrainingExpertise/TrainingExpertise.jsx";
import TrainingExpertiseCreate from "./views/TrainingExpertise/TrainingExpertiseCreate.jsx";
import TrainingExpertiseEdit from "./views/TrainingExpertise/TrainingExpertiseEdit.jsx";
import BookedSession from "./views/BookedSession/BookedSession.jsx";
import BookedSessionView from "./views/BookedSession/BookedSessionView.jsx";
import PersonalTrainerReviews from "./views/PersonalTrainer/PersonalTrainerReviews.jsx";
import SessionConfirmation from "./views/SessionConfirmation/SessionConfirmation.jsx";
function App(props) {
  // User
  const Users2 = lazy(() => import("./views/Users"));
  const Users = lazy(() => import("./views/Users/Users"));
  const UserView = lazy(() => import("./views/Users/UserView"));
  const UserEdit = lazy(() => import("./views/Users/UserEdit"));
  const UserCreate = lazy(() => import("./views/Users/UserCreate"));
  // Customers
  const Customers = lazy(() => import("./views/Customers/Customers"));
  const CustomerView = lazy(() => import("./views/Customers/CustomerView"));
  const CustomerEdit = lazy(() => import("./views/Customers/CustomerEdit"));
  const CustomerCreate = lazy(() => import("./views/Customers/CustomerCreate"));
  const CustomerSubscriptionsView = lazy(() =>
    import("./views/Customers/Subscriptions/CustomerSubscriptionsView")
  );
  const CustomerTasksView = lazy(() =>
    import("./views/Customers/Tasks/CustomerTasksView")
  );
  // ApplicationSettings
  const ApplicationSettingsEdit = lazy(() =>
    import("./views/ApplicationSettings/ApplicationSettingsEdit")
  );
  const ApplicationSettingsCreate = lazy(() =>
    import("./views/ApplicationSettings/ApplicationSettingsCreate")
  );
  // PromotionBanners
  const PromotionBannersEdit = lazy(() =>
    import("./views/PromotionBanners/PromotionBannersEdit")
  );
  const PromotionBannersCreate = lazy(() =>
    import("./views/PromotionBanners/PromotionBannersCreate")
  );
  // HelpfulTips
  const HelpfulTipsEdit = lazy(() =>
    import("./views/HelpfulTips/HelpfulTipsEdit")
  );
  const HelpfulTipsCreate = lazy(() =>
    import("./views/HelpfulTips/HelpfulTipsCreate")
  );
  // HelpfulTips
  const HomeCategoriesEdit = lazy(() =>
    import("./views/HomeCategories/HomeCategoriesEdit")
  );
  const HomeCategoriesCreate = lazy(() =>
    import("./views/HomeCategories/HomeCategoriesCreate")
  );
  // Questions
  const QuestionsEdit = lazy(() => import("./views/Questions/QuestionsEdit"));
  const QuestionsCreate = lazy(() =>
    import("./views/Questions/QuestionsCreate")
  );
  // Plans
  const Plans = lazy(() => import("./views/Plans/Plans"));
  const PlanEdit = lazy(() => import("./views/Plans/PlansEdit"));
  const PlanCreate = lazy(() => import("./views/Plans/PlansCreate"));
  // Meals
  const Meals = lazy(() => import("./views/Meals/Meals"));
  const MealEdit = lazy(() => import("./views/Meals/MealsEdit"));
  const MealCreate = lazy(() => import("./views/Meals/MealsCreate"));
  // FoodTypes
  const FoodTypes = lazy(() => import("./views/FoodTypes/FoodTypes"));
  const FoodTypeEdit = lazy(() => import("./views/FoodTypes/FoodTypesEdit"));
  const FoodTypeCreate = lazy(() =>
    import("./views/FoodTypes/FoodTypesCreate")
  );
  // CalorieCalculations
  const CalorieCalculations = lazy(() =>
    import("./views/CalorieCalculations/CalorieCalculations")
  );
  const CalorieCalculationEdit = lazy(() =>
    import("./views/CalorieCalculations/CalorieCalculationsEdit")
  );
  const CalorieCalculationCreate = lazy(() =>
    import("./views/CalorieCalculations/CalorieCalculationsCreate")
  );
  const CalorieCalculationUploadCsv = lazy(() =>
    import("./views/CalorieCalculations/CalorieCalculationUploadCsv")
  );
  // Workouts
  const Workouts = lazy(() => import("./views/Workouts/Workouts"));
  const WorkoutEdit = lazy(() => import("./views/Workouts/WorkoutsEdit"));
  const WorkoutCreate = lazy(() => import("./views/Workouts/WorkoutsCreate"));
  // MedicalInstructions
  const MedicalInstructions = lazy(() =>
    import("./views/MedicalInstructions/MedicalInstructions")
  );
  const MedicalInstructionEdit = lazy(() =>
    import("./views/MedicalInstructions/MedicalInstructionsEdit")
  );
  const MedicalInstructionCreate = lazy(() =>
    import("./views/MedicalInstructions/MedicalInstructionsCreate")
  );
  // ContentOfTheDays
  const ContentOfTheDays = lazy(() =>
    import("./views/ContentOfTheDays/ContentOfTheDays")
  );
  const ContentOfTheDayEdit = lazy(() =>
    import("./views/ContentOfTheDays/ContentOfTheDaysEdit")
  );
  const ContentOfTheDayCreate = lazy(() =>
    import("./views/ContentOfTheDays/ContentOfTheDaysCreate")
  );
  // DailyTasks
  const DailyTasks = lazy(() => import("./views/DailyTasks/DailyTasks"));
  const DailyTaskEdit = lazy(() => import("./views/DailyTasks/DailyTasksEdit"));
  const DailyTaskCreate = lazy(() =>
    import("./views/DailyTasks/DailyTasksCreate")
  );
  // Milestones
  const Milestones = lazy(() => import("./views/Milestones/Milestones"));
  const MilestoneEdit = lazy(() => import("./views/Milestones/MilestonesEdit"));
  const MilestoneCreate = lazy(() =>
    import("./views/Milestones/MilestonesCreate")
  );
  // RamazanTasks
  const RamazanTasks = lazy(() => import("./views/RamazanTasks/RamazanTasks"));
  const RamazanTaskEdit = lazy(() =>
    import("./views/RamazanTasks/RamazanTasksEdit")
  );
  const RamazanTaskCreate = lazy(() =>
    import("./views/RamazanTasks/RamazanTasksCreate")
  );
  // RamazanMilestones
  const RamazanMilestones = lazy(() =>
    import("./views/RamazanMilestones/RamazanMilestones")
  );
  const RamazanMilestoneEdit = lazy(() =>
    import("./views/RamazanMilestones/RamazanMilestonesEdit")
  );
  const RamazanMilestoneCreate = lazy(() =>
    import("./views/RamazanMilestones/RamazanMilestonesCreate")
  );
  // ContentOfTheDays
  const RamazanContentOfTheDays = lazy(() =>
    import("./views/RamazanContentOfTheDays/RamazanContentOfTheDays")
  );
  const RamazanContentOfTheDayEdit = lazy(() =>
    import("./views/RamazanContentOfTheDays/RamazanContentOfTheDaysEdit")
  );
  const RamazanContentOfTheDayCreate = lazy(() =>
    import("./views/RamazanContentOfTheDays/RamazanContentOfTheDaysCreate")
  );

  const Notifications = lazy(() =>
    import("./views/Notifications/Notifications")
  );
  const NotificationEdit = lazy(() =>
    import("./views/Notifications/NotificationsEdit")
  );
  const NotificationCreate = lazy(() =>
    import("./views/Notifications/NotificationsCreate")
  );
  // AppContent
  const AppContent = lazy(() => import("./views/AppContent"));

  //Sections
  const Sections = lazy(() => import("./views/Sections/Sections"));
  const SectionEdit = lazy(() => import("./views/Sections/SectionsEdit"));
  const SectionCreate = lazy(() => import("./views/Sections/SectionsCreate"));

  const Faqs = lazy(() => import("./views/Faqs/Faqs"));
  const FaqsEdit = lazy(() => import("./views/Faqs/FaqsEdit"));
  const FaqsCreate = lazy(() => import("./views/Faqs/FaqsCreate"));

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      props.resetCommonReducerErrorsAction();
      if (!location.pathname.includes("admin/meal"))
        props.setPageNumberMealAction();
      if (
        location.pathname.includes("admin/dailyTask") ||
        location.pathname.includes("admin/milestone")
      ) {
      } else props.setPageNumberDailyTaskAction();
    }
  }, [location]);
  useEffect(() => {
    if (!props.token && !location.pathname.includes("session-confirmation")) {
      navigate("/");
    }
  }, [props.token]);

  return (
    <Suspense fallback={false}>
      <Routes>
        <Route
          path="session-confirmation/:hash"
          element={<SessionConfirmation />}
        />
        {!props.token ? (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
          </>
        ) : (
          <>
            <Route path="/admin" element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
              {/* user routes */}

              <Route path="users" element={<Users />} />
              <Route path="user/:id" element={<UserView />} />
              <Route path="user/edit/:id" element={<UserEdit />} />
              <Route path="user/create" element={<UserCreate />} />
              {/* customer routes */}
              <Route path="customers" element={<Customers />} />
              <Route path="customer/:id" element={<CustomerView />} />
              <Route path="customer/edit/:id" element={<CustomerEdit />} />
              <Route path="customer/create" element={<CustomerCreate />} />
              <Route
                path="customer/subscriptions/:id"
                element={<CustomerSubscriptionsView />}
              />
              <Route
                path="customer/tasks/:id"
                element={<CustomerTasksView />}
              />
              {/* applicationSettings routes */}
              <Route
                path="applicationSettings/edit/:id"
                element={<ApplicationSettingsEdit />}
              />
              <Route
                path="applicationSettings/create"
                element={<ApplicationSettingsCreate />}
              />
              {/* applicationSettings routes */}
              <Route
                path="promotionBanners/edit/:id"
                element={<PromotionBannersEdit />}
              />
              <Route
                path="promotionBanners/create"
                element={<PromotionBannersCreate />}
              />
              {/* helpfulTips routes */}
              <Route
                path="helpfulTips/edit/:id"
                element={<HelpfulTipsEdit />}
              />
              <Route
                path="helpfulTips/create"
                element={<HelpfulTipsCreate />}
              />
              {/* homeCategories routes */}
              <Route
                path="homeCategories/edit/:id"
                element={<HomeCategoriesEdit />}
              />
              <Route
                path="homeCategories/create"
                element={<HomeCategoriesCreate />}
              />
              {/* questions routes */}
              <Route path="questions/edit/:id" element={<QuestionsEdit />} />
              <Route path="questions/create" element={<QuestionsCreate />} />
              {/* plans routes */}
              <Route path="plans" element={<Plans />} />
              <Route path="plan/edit/:id" element={<PlanEdit />} />
              <Route path="plan/create" element={<PlanCreate />} />
              {/* Meals routes */}
              <Route path="meals" element={<Meals />} />
              <Route path="meals/:page" element={<Meals />} />
              <Route path="meal/edit/:id" element={<MealEdit />} />
              <Route path="meal/create" element={<MealCreate />} />
              {/* FoodTypes routes */}
              <Route path="foodTypes" element={<FoodTypes />} />
              <Route path="foodType/edit/:id" element={<FoodTypeEdit />} />
              <Route path="foodType/create" element={<FoodTypeCreate />} />
              {/* CalorieCalculations routes */}
              <Route
                path="calorieCalculations"
                element={<CalorieCalculations />}
              />
              <Route
                path="calorieCalculation/edit/:id"
                element={<CalorieCalculationEdit />}
              />
              <Route
                path="calorieCalculation/create"
                element={<CalorieCalculationCreate />}
              />
              <Route
                path="calorieCalculation/upload/csv"
                element={<CalorieCalculationUploadCsv />}
              />
              {/* Workouts routes */}
              <Route path="workouts" element={<Workouts />} />
              <Route path="workout/edit/:id" element={<WorkoutEdit />} />
              <Route path="workout/create" element={<WorkoutCreate />} />
              {/* MedicalInstructions routes */}
              <Route
                path="medicalInstructions"
                element={<MedicalInstructions />}
              />
              <Route
                path="medicalInstruction/edit/:id"
                element={<MedicalInstructionEdit />}
              />
              <Route
                path="medicalInstruction/create"
                element={<MedicalInstructionCreate />}
              />
              {/* ContentOfTheDays routes */}
              <Route path="contentOfTheDays" element={<ContentOfTheDays />} />
              <Route
                path="contentOfTheDay/edit/:id"
                element={<ContentOfTheDayEdit />}
              />
              <Route
                path="contentOfTheDay/create"
                element={<ContentOfTheDayCreate />}
              />
              {/* DailyTasks routes */}
              <Route path="dailyTasks" element={<DailyTasks />} />
              <Route path="dailyTask/edit/:id" element={<DailyTaskEdit />} />
              <Route path="dailyTask/create" element={<DailyTaskCreate />} />
              {/* <Route path="dailyTask/milestones/:id" element={<DailyTasksUploadImage />} /> */}
              {/* Milestones routes */}
              <Route path="milestones" element={<Milestones />} />
              <Route path="milestone/edit/:id" element={<MilestoneEdit />} />
              <Route path="milestone/create" element={<MilestoneCreate />} />
              {/* RamazanTasks routes */}
              <Route path="ramazanTasks" element={<RamazanTasks />} />
              <Route
                path="ramazanTask/edit/:id"
                element={<RamazanTaskEdit />}
              />
              <Route
                path="ramazanTask/create"
                element={<RamazanTaskCreate />}
              />
              {/* RamazanMilestones routes */}
              <Route
                path="ramazan-milestones"
                element={<RamazanMilestones />}
              />
              <Route
                path="ramazan-milestone/edit/:id"
                element={<RamazanMilestoneEdit />}
              />
              <Route
                path="ramazan-milestone/create"
                element={<RamazanMilestoneCreate />}
              />
              {/* RamazanContentOfTheDays routes */}
              <Route
                path="ramazanContentOfTheDays"
                element={<RamazanContentOfTheDays />}
              />
              <Route
                path="ramazanContentOfTheDay/edit/:id"
                element={<RamazanContentOfTheDayEdit />}
              />
              <Route
                path="ramazanContentOfTheDay/create"
                element={<RamazanContentOfTheDayCreate />}
              />
              <Route path="workouts2" element={<Workouts2 />} />
              <Route path="meal-plans" element={<MealPlans />} />
              <Route path="daily-tasks" element={<DailyTask />} />
              <Route
                path="calorie-calculations"
                element={<CalorieCalculations2 />}
              />
              <Route
                path="push-notifications"
                element={<PushNotifications />}
              />
              <Route path="chat-support" element={<ChatSupport />} />
              <Route path="app-content" element={<AppContent />} />
              <Route
                path="subscription-plans"
                element={<SubscriptionPlans />}
              />
              {/* inner pages */}
              <Route path="new-guide" element={<NewGuide />} />
              <Route path="new-workouts" element={<NewWorkouts />} />
              <Route path="new-daily-task" element={<NewDailyTask />} />
              <Route path="new-calorie-item" element={<NewCalorieItem />} />
              <Route
                path="new-notification"
                element={<NewPushNotifications />}
              />
              <Route path="update-banner" element={<UpdateBanner />} />
              <Route
                path="new-subscription"
                element={<NewSubscriptionPlan />}
              />
              <Route path="Users2" element={<Users2 />} />
              <Route path="Icons" element={<Icons />} />
              <Route path="sections" element={<Sections />} />
              <Route path="section/edit/:id" element={<SectionEdit />} />
              <Route path="section/create" element={<SectionCreate />} />
              <Route path="notifications" element={<Notifications />} />
              <Route
                path="notifications/edit/:id"
                element={<NotificationEdit />}
              />
              <Route
                path="notifications/create"
                element={<NotificationCreate />}
              />
              <Route path="faqs" element={<Faqs />} />
              <Route path="faqs/edit/:id" element={<FaqsEdit />} />
              <Route path="faqs/create" element={<FaqsCreate />} />

              <Route path="personal-trainer" element={<PersonalTrainer />} />
              <Route
                path="personal-trainer/edit/:id"
                element={<PersonalTrainerEdit />}
              />
              <Route
                path="package-trainer-prices/list/:packageId"
                element={<PackageTrainerPrice />}
              />
              <Route
                path="package-trainer-prices/create/:packageId"
                element={<PackageTrainerPriceCreate />}
              />
              <Route
                path="package-trainer-prices/edit/:id"
                element={<PackageTrainerPriceEdit />}
              />
              <Route
                path="personal-trainer/create"
                element={<PersonalTrainerCreate />}
              />
              <Route
                path="training-expertise"
                element={<TrainingExpertise />}
              />
              <Route
                path="training-expertise/edit/:id"
                element={<TrainingExpertiseEdit />}
              />
              <Route
                path="training-expertise/create"
                element={<TrainingExpertiseCreate />}
              />
              <Route
                path="personal-trainer/reviews/:trainerId"
                element={<PersonalTrainerReviews />}
              />
              <Route path="booked-sessions" element={<BookedSession />} />
              <Route
                path="booked-sessions/view/:id"
                element={<BookedSessionView />}
              />
            </Route>
            <Route
              path="*"
              element={<Navigate replace to="/admin/dashboard" />}
            />
          </>
        )}
      </Routes>
    </Suspense>
  );
}

const mapStateToProps = (state) => ({
  token: state.userReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
  resetCommonReducerErrorsAction: (data) =>
    dispatch(resetCommonReducerErrorsAction(data)),
  setPageNumberMealAction: (data) => dispatch(setPageNumberMealAction(data)),
  setPageNumberDailyTaskAction: (data) =>
    dispatch(setPageNumberDailyTaskAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
