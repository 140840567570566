import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    Row, 
    Col,
    FormGroup,
    Label,
    Input,
    Table,
} from "reactstrap";
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom";
import Discart from "../../components/Buttons/Discart";
import SaveAndPublish from "../../components/Buttons/SaveAndPublish";
import { bookedSessionViewAction } from "../../redux/actions/bookedSessionAction";

function BookedSessionView (props) {
    const { id } = useParams();
    const navigate = useNavigate()

    useEffect(()=>{
        props.bookedSessionViewAction(id).then(response => {});
    } , [])

    const UpdateBookedSessionStatus = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        props.trainerPricesUpdateAction(formData, id, navigate).then(response => {});
    }
    return (
        <>
            <div className="content">
                <Card className="breadCrumb">
                    <CardBody>
                      <div className="tpf-horizontal-card">
                          <Row>
                              <Col xl="4">
                                  <div className="d-flex">
                                      <div>
                                          <Link to="/admin/booked-sessions">
                                          <figure className="figImage d-flex align-items-center">
                                              <svg width="50" height="50" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <circle opacity="0.2" cx="18" cy="18" r="18" fill="#4F76D9"/>
                                                  <path d="M13.6475 16.758C13.3486 17.0568 13.3486 17.5549 13.6475 17.8537L20.0889 24.3283C20.4209 24.6271 20.9189 24.6271 21.2178 24.3283L21.9814 23.5646C22.2803 23.2658 22.2803 22.7678 21.9814 22.4357L16.8682 17.2892L21.9814 12.176C22.2803 11.8439 22.2803 11.3459 21.9814 11.0471L21.2178 10.2834C20.9189 9.98456 20.4209 9.98456 20.0889 10.2834L13.6475 16.758Z" fill="white"/>
                                              </svg>
                                          </figure>
                                          </Link>
                                      </div>
                                      <div className="pl-3 flex-shrink-1 w-100 d-flex align-items-center">
                                          <div className="title">
                                              <h5 className="card-title font-weight-bold">View Booked Session</h5>
                                              <ul className="breadCrumbList">
                                                  <li><a href="javascript:;" path="/admin/dashboard" >Home</a></li>
                                                  <li><a href="javascript:;" path={`/admin/booked-sessions`} >Booked Sessions</a></li>
                                                  <li><a href="javascript:;" path={`/admin/booked-sessions/view`} >View</a></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </Col>

                              
                          </Row>
                      </div>
                    </CardBody>
                </Card>

                <Card className="guide-card">
                    <CardBody>
                        <form onSubmit={UpdateBookedSessionStatus} encType="multipart/form-data">
                            <div className="form-row" >
                                
                                {/* Invoice Header */}
                                <div className="col-lg-12">
                                    <h2>Training Session Details</h2>
                                    <hr />
                                </div>
                                
                                {/* Invoice Number and Date */}
                                <FormGroup className="col-lg-6">
                                    <Label for="invoiceNumber">ID</Label>
                                    <Input type="text" id="invoiceNumber" value={props.booked_session.id} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="invoiceDate">Booking Date</Label>
                                    <Input type="text" id="invoiceDate" value={props.booked_session.booking_date} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="invoiceDate">Status</Label>
                                    <Input type="text" id="invoiceDate" value={props.booked_session.status} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="invoiceDate">Is Paid</Label>
                                    <Input type="text" id="invoiceDate" value={props.booked_session.is_paid ? "Successfully" : "Pending"} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="invoiceDate">Stripe Id</Label>
                                    <Input type="text" id="invoiceDate" value={props.booked_session.stripe_payment_id} readOnly />
                                </FormGroup>
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                {/* Billing Information */}
                                <div className="col-lg-12">
                                    <h4>Customer Information</h4>
                                    <hr />
                                </div>

                                <FormGroup className="col-lg-6">
                                    <Label for="billingTo">ID</Label>
                                    <Input type="text" id="billingTo" value={props.booked_session?.customer?.id} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="billingTo">Name</Label>
                                    <Input type="text" id="billingTo" value={props.booked_session?.customer?.full_name} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="billingAddress">Phone Number</Label>
                                    <Input type="text" id="billingAddress" value={props.booked_session?.customer?.phone_number} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="billingAddress">Email</Label>
                                    <Input type="text" id="billingAddress" value={props.booked_session?.customer?.email} readOnly />
                                </FormGroup>
                                
                                {/* Item Details */}
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h4>Package Details</h4>
                                    <hr />
                                </div>

                                <FormGroup className="col-lg-6">
                                    <Label for="itemDescription">ID</Label>
                                    <Input type="text" id="itemDescription" value={props.booked_session?.package?.id} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="itemDescription">Title</Label>
                                    <Input type="text" id="itemDescription" value={props.booked_session?.package?.name ?? null} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="itemDescription">Description</Label>
                                    <Input type="text" id="itemDescription" value={props.booked_session?.package?.description ?? null} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="itemDescription">Description</Label>
                                    <Input type="text" id="itemDescription" value={props.booked_session?.package?.description ?? null} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Stripe Product Id</Label>
                                    <Input type="text" id="quantity" value={props.booked_session?.package?.stripe_product_id} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Quantity</Label>
                                    <Input type="text" id="quantity" value="1" readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="unitPrice">Total Amount</Label>
                                    <Input type="text" id="unitPrice" value={props.booked_session?.price?.price.toFixed(2)} readOnly />
                                </FormGroup>
                                
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h4>Pricing & Discount</h4>
                                    <hr />
                                </div>         

                                
                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Coupon</Label>
                                    <Input type="text" id="quantity" value={props.booked_session?.coupon} readOnly />
                                </FormGroup>      

                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Sub Total</Label>
                                    <Input type="text" id="quantity" value={props.booked_session?.subtotal} readOnly />
                                </FormGroup>    

                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Discount</Label>
                                    <Input type="text" id="quantity" value={props.booked_session?.discount} readOnly />
                                </FormGroup>     

                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Total Amount</Label>
                                    <Input type="text" id="quantity" value={props.booked_session?.total_amount} readOnly />
                                </FormGroup>     

                                {/* Invoice Status and Notes */}
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h4>Trainer Information</h4>
                                    <hr />
                                </div>

                                <FormGroup className="col-lg-6">
                                    <Label for="itemDescription">Trainer ID</Label>
                                    <Input type="text" id="itemDescription" value={props.booked_session?.trainer?.id ?? null} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="itemDescription">Trainer Name</Label>
                                    <Input type="text" id="itemDescription" value={props.booked_session?.trainer?.name ?? null} readOnly />
                                </FormGroup>

                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Trainer Email</Label>
                                    <Input type="text" id="quantity" value={props.booked_session?.trainer?.email} readOnly />
                                </FormGroup>      


                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h4>Reviews</h4>
                                    <hr />
                                </div>         

                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Rating</Label>
                                    <Input type="text" id="quantity" value={props.booked_session?.review?.rating} readOnly />
                                </FormGroup>     

                                <FormGroup className="col-lg-6">
                                    <Label for="quantity">Review</Label>
                                    <Input type="text" id="quantity" value={props.booked_session?.review?.review} readOnly />
                                </FormGroup>                       

                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h4>Status Logs</h4>
                                    <hr />
                                </div>         
                
                                <Table responsive className="tablesorter tpf-table">
                                    <thead className="text-primary">
                                        <tr>
                                            <th>Title</th>
                                            <th>Marking Entity</th>
                                            <th>Marked By</th>
                                            <th>Marked At</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {props?.booked_session?.status_logs?.map((status) => (
                                            <tr key={status.id}>
                                                <td>{status.slug}</td>
                                                <td>{status?.marking_entity}</td>
                                                <td>
                                                    {status?.marked_by_customer?.email 
                                                        ? status.marked_by_customer.email 
                                                        : status?.marked_by_user?.email 
                                                        ? status.marked_by_user.email 
                                                        : status?.marked_by_trainer?.name 
                                                            ? status.marked_by_trainer.name 
                                                            : 'N/A'
                                                    }
                                                </td>
                                                <td>{status?.createdAt}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>                
                                {/* Form Actions */}
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end align-items-center w-100">
                                    <Discart status_field={true} back={"booked-sessions"} />
                                        {/* <SaveAndPublish status_field={true} /> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>

                
            </div>
        </>
    )
}

const mapStateToProps = state => ({
  booked_session: state.bookedSessionReducer.booked_session,
  errors: state.commonReducer.errors,
  show_loder: state.commonReducer.show_loder,
})

const mapDispatchToProps = (dispatch) => ({
    bookedSessionViewAction: (data) => dispatch( bookedSessionViewAction(data) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookedSessionView)