import React, { useState, useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    Table,
    Badge,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { PersonalTrainerReviewsListAction } from "../../redux/actions/PersonalTrainerAction";
import { connect } from "react-redux"
import Pagination from "../../components/Pagination/Pagination";

function PeronsalTrainerReviews (props) {
    const { trainerId } = useParams();
    const [currentPageNumber, setcurrentPageNumber] = useState(1);
  
    useEffect(()=>{
      if(currentPageNumber) props.PersonalTrainerReviewsListAction(trainerId,currentPageNumber).then(response => {});
    },[currentPageNumber]);

    return (
        <>
          <div className="content">
            <Row>
                <Col xl="4">
                  <div className="d-flex">
                      <div>
                          <Link to="admin/personal-trainer">
                          <figure className="figImage d-flex align-items-center">
                              <svg width="50" height="50" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle opacity="0.2" cx="18" cy="18" r="18" fill="#4F76D9"/>
                                  <path d="M13.6475 16.758C13.3486 17.0568 13.3486 17.5549 13.6475 17.8537L20.0889 24.3283C20.4209 24.6271 20.9189 24.6271 21.2178 24.3283L21.9814 23.5646C22.2803 23.2658 22.2803 22.7678 21.9814 22.4357L16.8682 17.2892L21.9814 12.176C22.2803 11.8439 22.2803 11.3459 21.9814 11.0471L21.2178 10.2834C20.9189 9.98456 20.4209 9.98456 20.0889 10.2834L13.6475 16.758Z" fill="white"/>
                              </svg>
                          </figure>
                          </Link>
                      </div>
                      <div className="pl-3 flex-shrink-1 w-100 d-flex align-items-center">
                          <div className="title">
                              <h5 className="card-title font-weight-bold">Edit Personal Trainer</h5>
                              <ul className="breadCrumbList">
                                  <li><a href="javascript:;" path="/admin/dashboard" >Home</a></li>
                                  <li><a href="javascript:;" path="/admin/personal-trainer" >Personal Trainer</a></li>
                                  <li><a href="javascript:;" path="/admin/personal-trainer" >Edit</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </Col>
              <Col md="12">
                <Card className="card-plain tpf-card">
                <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">
                          Reviews & Ratings
                          <Badge color="info" pill>Total Reviews: {props?.personal_trainer_reviews?.rating?.total_reviews}</Badge>
                          <Badge color="info" pill>Overall Rating: {props?.personal_trainer_reviews?.rating?.overall_rating}</Badge>
                        </CardTitle>
                        {/* <p className="category">Sort by:</p> */}
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Table responsive className="tablesorter tpf-table">
                      <thead className="text-primary">
                        <tr>
                          <th>Customer</th>
                          <th>Customer Email</th>
                          <th>Rating</th>
                          <th>Review</th>
                          <th>Training Session</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props?.personal_trainer_reviews.records?.map((review) => (
                          <tr key={review.id}>
                            <td>{review?.customer?.fullName}</td>
                            <td>{review?.customer?.email}</td>
                            <td>{review?.rating}</td>
                            <td>{review?.review}</td>
                            <td>{review?.trainingSession?.package?.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination 
                      current_page={props?.personal_trainer_reviews_pagination?.currentPage}
                      limit={props?.personal_trainer_reviews_pagination?.limit}
                      previous={props?.personal_trainer_reviews_pagination?.previousPage}
                      next={props?.personal_trainer_reviews_pagination?.nextPage}
                      total_record={props?.personal_trainer_reviews_pagination?.totalCount}
                      total_pages={props?.personal_trainer_reviews_pagination?.totalPages}
                      setcurrentPageNumber={setcurrentPageNumber}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    )
}

const mapStateToProps = state => ({
  personal_trainer_reviews: state.personalTrainerReducer.personal_trainer_reviews,
  personal_trainer_reviews_pagination: state.personalTrainerReducer.personal_trainer_reviews_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  PersonalTrainerReviewsListAction: (trainerId, data, limit) => dispatch( PersonalTrainerReviewsListAction(trainerId,data, limit) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeronsalTrainerReviews)