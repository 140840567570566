import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Row, 
    Col,
    Button,
    Table,
    Badge,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { getBookedSessionListAction } from "../../redux/actions/bookedSessionAction";
import { connect } from "react-redux"
import Pagination from "../../components/Pagination/Pagination";
import ViewIcon from "../../components/Icons/ViewIcon";
import { useNavigate } from "react-router-dom";
import SearchFieldNav from "../../components/SearchFieldNav/SearchFieldNav";

function BookedSession (props) {
    const navigate = useNavigate();
    const [currentPageNumber, setcurrentPageNumber] = useState(1);
    const [searchBy, setSearchBy] = useState("");

    useEffect(()=>{
      if(currentPageNumber) props.getBookedSessionListAction(currentPageNumber,"","").then(response => {});
    },[currentPageNumber]);

    useEffect(()=>{
      if(searchBy === "0") {
        props.getBookedSessionListAction(1, "", ).then(response => {});
      }else if(searchBy) {
        props.getBookedSessionListAction(1, "").then(response => {});
      }
    },[searchBy]);

    return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card className="card-plain tpf-card">
                <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">
                          Booked Sessions
                          <Badge color="info" pill>{props.booked_sessions_pagination?.totalCount}</Badge>
                        </CardTitle>
                        {/* <p className="category">Sort by:</p> */}
                        <div className="selectDown">
                          <FormGroup>
                            <Label for="sortBy">Sort by:</Label>
                            <Input type="select" name="sortBy" id="SortsortByBy" >
                              <option>Recent</option>
                              <option>...</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </Col>

                      <Col>
                        <div className="filter">
                          <SearchFieldNav setSearchBy={setSearchBy} />
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Table responsive className="tablesorter tpf-table">
                      <thead className="text-primary">
                        <tr>
                          <th>ID</th>
                          <th>Package</th>
                          <th>Trainer</th>
                          <th>Customer</th>
                          <th>Price</th>
                          <th>Booking Date</th>
                          <th>Status</th>
                          <th>Promo Code</th>
                          <th>Sub Total</th>
                          <th>Discount</th>
                          <th>Total Amount</th>
                          <th>Is Paid</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props?.booked_sessions?.map((booked_session) => (
                          <tr key={booked_session.id}>
                            <td>{booked_session.id}</td>
                            <td>{booked_session.package.name}</td>
                            <td>{booked_session?.trainer.name}</td>
                            <td>{booked_session?.customer.full_name}</td>
                            <td>{booked_session?.price?.price?.toFixed(2)}</td>
                            <td>{booked_session?.booking_date}</td>
                            <td>{booked_session?.status}</td>
                            <td>{booked_session?.coupon}</td>
                            <td>{booked_session?.subtotal}</td>
                            <td>{booked_session?.discount}</td>
                            <td>{booked_session?.total_amount}</td>
                            <td>{booked_session?.is_paid ? "successful" : "pending"}</td>
                            <td className="text-right">
                              <Button className="btn-icon" color="success" size="sm" onClick={()=> {
                                navigate('/admin/booked-sessions/view/'+booked_session.id, { replace: true, state: booked_session })
                              }}>
                                <ViewIcon />
                              </Button>{` `}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination 
                      current_page={props?.booked_sessions_pagination?.currentPage}
                      limit={props?.booked_sessions_pagination?.limit}
                      previous={props?.booked_sessions_pagination?.previousPage}
                      next={props?.booked_sessions_pagination?.nextPage}
                      total_record={props?.booked_sessions_pagination?.totalCount}
                      total_pages={props?.booked_sessions_pagination?.totalPages}
                      setcurrentPageNumber={setcurrentPageNumber}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    )
}

const mapStateToProps = state => ({
  booked_sessions: state.bookedSessionReducer.booked_sessions,
  booked_sessions_pagination: state.bookedSessionReducer.booked_sessions_pagination,
})

const mapDispatchToProps = (dispatch) => ({
  getBookedSessionListAction: (data, limit, searchBy) => dispatch( getBookedSessionListAction(data, limit, searchBy) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookedSession)